body {
  margin: 0;
  max-width: 100%;
  width: 100%;
}
h1,
h2,
h3,
h4,
h5,
span,
p,
text,
div {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

/* home page */

.sub-header {
  padding: 0;
  margin: 0;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo-subheader {
  background-color: #f0f8ff;
  opacity: 0.7;
  top: 45%;
  padding: 0.5rem;
  border-radius: 20px 20px;
  animation: fadeIn ease 20s;
  position: absolute;
}
.recommended-card-home {
  background-color: white;
  height: 300px;
  text-align: right;
  font-size: 1rem;
  border: 1px solid black;
  border-radius: 0%;
  /* margin: 0 20%; */
  padding: 1rem 2rem;
  box-sizing: border-box;
  overflow: scroll;
}
.header-img-home-group {
  font-size: 1.5rem;
  font-weight: 500;
}
.home-card-apartment {
  border: 2px solid rgb(234, 234, 234);
}
.home-card-apartment:hover {
  border: 2px solid black;
  opacity: 0.8;
}
.image-recommended {
  width: 100%;
  height: auto;
}
.crop {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.image-subheader {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  object-position: top;
}

.slide-image {
  height: 200px;
  width: 300px;
  object-fit: cover;
  cursor: pointer;
}
.line-sell {
  position: relative;
  top: 10%;
  left: -15%;
  width: 53%;
  height: 12%;
  background-color: green;
  border: solid 2px black;
  transform: rotate(-45deg);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  color: white;
}
.line-sold {
  position: relative;
  top: 10%;
  left: -15%;
  width: 53%;
  height: 20%;
  color: white;
  background-color: red;
  border: solid 2px black;
  transform: rotate(-45deg);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}
/* .slide-image-1 {
  height: 200px;
  width: 300px;
  object-fit: fill;
} */
/* .home-card-apartment-1 {
  position: relative;
  height: 270px;
  width: auto;
} */
.slide-image:hover {
  opacity: 0.8;
}
.group-com-roi {
  height: 120px;
}
.image-group-com {
  border-radius: 100%;
  height: 200px;
  width: auto;
}
/* apartment page */
.apartment-page-carousel {
  padding: 0 15%;
}
.apartment-page-title {
  font-size: 2rem;
  margin: 2rem 0;
}
.icon-property {
  align-self: center;
  width: 3vw;
  height: auto;
}
/* apartment sell */

.apartments-sell-section {
  text-align: center;
  max-width: 950px;
  width: 950px;
  margin-left: auto;
  margin-right: auto;
}
.image-sell-apartment {
  width: 450px;
  height: 300px;
  object-fit: cover;
}
.apartments-sell-grid-item {
  padding: 1rem;
}
.apartment-card {
  /* height: auto; */
  width: 450px;
  /* object-fit: cover; */
}
.grid-apartment-sell {
  /* padding: 0 13%; */
}
.map-padding {
  padding: 0 13.5%;
}

.image-map {
  height: 9rem;
  width: auto;
  object-fit: scale-down;
}
.login-home-images {
  width: 30rem;
  height: auto;
}
.mapboxgl-popup-close-button {
  background-color: white !important;
}
.mapboxgl-popup-content {
  border: 1px solid black;
  padding: 0 !important;
}

/* contact page */
.contact-content {
  padding: 0 15rem;
}
.user-details {
  border: 2px solid black;
  margin: 0% 30% 4% 30%;
  padding-bottom: 1rem;
}
.user-details-title {
  font-size: 2rem;
}
.contact-icon {
  width: 6rem;
  height: auto;
  padding: 1rem;
}
/* our story */

.grid-rec {
  margin: 0;
  width: 100%;
  padding: 1rem 15%;
}
/* group */
.group-manager-div {
  padding: 4rem 8rem 0;
  text-align: right;
  display: flex;
  flex-direction: row;

  align-items: center;
  margin-bottom: 2rem;
}
.group-subDiv {
  height: "200px";
  display: "block";
  margin-right: 2rem;
}
/* blog */
.blog-container {
  text-align: center;
  padding: 0 5rem;
}
.header-blog {
  color: #0c0c0c;
  margin: 2rem;
}
.blog-sub-title {
  color: #777777;
  font-size: 0.8rem;
}
.blog-image {
  height: 100%;
  width: auto;
}
/* article */
.article-div {
  text-align: right;
  position: relative;
  margin: 2rem 10rem;
}
.article-details {
  margin: 2rem 0;
  border-width: 1px 0;
  padding: 15px 0;
  border-style: dotted;
}
.article-image-height {
  height: 200px;
  width: auto;
  object-fit: contain;
  text-align: right;
}

.article-image-width {
  height: auto;
  width: 100%;
  object-fit: contain;
  text-align: right;
}
.article-content {
  text-align: right;
  margin-top: 2rem;
  line-height: 1.5;
}
.article-content h2,
p {
  line-height: 1.2;
}
/* header/footer */
.footer-map {
  height: auto;
  width: 60%;
  padding-bottom: 2rem;
}
@-moz-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* IE */
@-ms-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* Opera and prob css3 final iteration */
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.blink-image {
  -moz-animation: blink normal 2s infinite ease-in-out; /* Firefox */
  -webkit-animation: blink normal 2s infinite ease-in-out; /* Webkit */
  -ms-animation: blink normal 2s infinite ease-in-out; /* IE */
  animation: blink normal 2s infinite ease-in-out; /* Opera and prob css3 final iteration */
}

@media only screen and (max-width: 768px) {
  .blink-image {
    width: 100vw;
    height: auto;
  }
  /* home */
  .logo-subheader {
    top: 5rem;
  }
  .image-subheader {
    height: auto;
    object-fit: fill;
    object-position: none;
  }
  .header-img-home-group {
    font-size: 1.1rem;
  }
  .sub-header-img-home-group {
    font-size: 0.7rem;
  }
  .sub-h1 {
    font-size: 1.5rem;
  }
  .sub-h3 {
    font-size: 1rem;
  }
  .slide-image {
    width: 100%;
  }
  .home-recommended {
    padding: 0 2rem 1rem;
  }
  .recommended-card-home {
    margin: 0 5%;
    font-size: 0.6rem;
  }
  .group-com-roi {
    height: 100%;
  }
  .home-card-apartment {
    border: none;
  }
  .home-card-apartment:hover {
    border: none;
  }
  /* apartment sell */

  .apartments-sell-grid-item {
    padding: 1rem 0;
  }
  .image-sell-apartment {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .apartment-card {
    /* height: auto; */
    width: 100%;
    /* object-fit: cover; */
  }
  .apartments-sell-section {
    width: 100%;
    max-width: 100%;
  }
  .grid-apartment-sell {
    padding: 2% 0;
  }
  .map-padding {
    padding: 0;
  }
  .image-group-com {
    height: 150px;
  }
  /* apartment page */
  .icon-property {
    width: 7vw;
  }
  .apartment-page-title {
    font-size: 1rem;
  }
  .apartment-page-carousel {
    padding: 0;
  }
  /* contact us */
  .user-details {
    margin: 0;
  }
  .user-details-title {
    font-size: 1.5rem;
  }
  .contact-icon {
    width: 64px;
    height: 64px;
  }
  .contact-content {
    padding: 0;
  }
  .font-icon-contact {
    font-size: 0.8rem;
  }
  /* our story  */
  .grid-rec {
    padding: 1rem 0;
  }
  /* our group */
  .group-manager-div {
    padding: 3rem 3rem 0;
    text-align: center;
    display: flex;
    flex-direction: column;
  }
  .group-subDiv {
    margin-right: 0;
    margin-top: 1rem;
  }
  /* blog */
  .blog-container {
    padding: 0;
  }
  .header-blog {
    font-size: x-large;
  }
  /* article */
  .article-div {
    margin: 0;
    padding: 1rem;
  }
  .article-details {
    display: flex;
    flex-direction: column;
  }
  .article-content img {
    width: 100%;
  }
  /* header/footer */
  .footer-map {
    height: auto;
    width: 100%;
  }
}
